import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

function BashInput({textRef, handleSubmit}) {
  const [active, setActive] = useState(false);
  const [pos, setPos] = useState(0);
  const [value, setValue] = useState('');

  const handleChange = e => {
    setValue(e.target.value);
    setPos(e.target.selectionStart);
  };

  const handleKeyUp = e => {
    setPos(e.target.selectionStart);
  };

  const onSubmit = e => {
    e.preventDefault();
    handleSubmit(value);
    setValue('');
  };

  return (
    <form onSubmit={onSubmit} className={styles.root}>
      <input
        type="text"
        ref={textRef}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        onChange={handleChange}
        value={value}
        onKeyUp={handleKeyUp}
        className={styles.input}
      />
      <span className={styles.prefix}>{value.substring(0, pos)}</span>
      <span
        className={classNames([
          styles.cursor,
          {[styles.isLast]: pos === value.length, [styles.active]: active},
        ])}
      >
        {value.substring(pos, pos + 1)}
      </span>
      <span className={styles.suffix}>{value.substring(pos + 1)}</span>
    </form>
  );
}

BashInput.propTypes = {
  textRef: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default BashInput;
