import React from 'react';

import styles from './index.module.scss';

function ProgressBar() {
  return (
    <div>
      <span className={styles.input}>{'· '.repeat(20)} 100%</span>
    </div>
  );
}

export default ProgressBar;
