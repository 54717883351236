const initialState = {
  fileSystem: {
    bin: {},
    usr: {
      bin: {},
      lib: {},
    },
    Volumes: {},
    Users: {
      darrikonn: {
        '.vimrc': `
          let mapleader=","

          set history=700

          set number
          set relativenumber

          syntax enable
        `.trim(),
        '.dkshrc': `
          DKSH_THEME="darrnoster"
          eval "$(direnv hook dksh)"
        `.trim(),
        Desktop: {},
        Documents: {
          repos: {},
        },
        Downloads: {},
      },
    },
  },
  cwd: 'Users/darrikonn',
};

export default (state = initialState, action) => {
  // const {payload} = action;

  if (action.error) {
    return state;
  } else if (action.pending) {
    return state;
  }
  switch (action.type) {
    default:
      return state;
  }
};
