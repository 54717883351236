import React from 'react';

import styles from './index.module.scss';

function Social() {
  return (
    <div className={styles.root}>
      <ul>
        <li>
          <a
            href="https://github.com/darrikonn"
            title="View my work on Github"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-github" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/darri-konradsson-985514b9"
            title="Contact me on Linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin" />
          </a>
        </li>
        <li>
          <a
            href="mailto:darrikonn@gmail.com"
            title="Email me"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-envelope" />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Social;
