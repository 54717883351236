import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {BashInput, BashOutput, Prompt} from '..';

import bash from 'services/bash';

import styles from './index.module.scss';

function Terminal() {
  const [history, setHistory] = useState([]);
  const bashState = useSelector(({bash}) => bash);
  const contentEndRef = useRef(null);
  const textRef = useRef(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    !isSafari && contentEndRef.current.scrollIntoViewIfNeeded();
  }, [history, isSafari]);

  const runCommand = command => {
    setHistory([
      ...history,
      {ps1: `▲ ${bashState.cwd}`, command: command, output: bash(bashState, command)},
    ]);
  };

  const focusInput = () => textRef.current.focus();

  return (
    <div className={styles.root}>
      <div className={styles.bar}>
        <div className={styles.windowButtons}>
          <span />
          <span />
          <span />
        </div>
        <div className={styles.dksh}>dksh</div>
        <div />
      </div>
      {isSafari ? (
        <span className={styles.safari}>
          Please use Chrome in order to interact with the Terminal
        </span>
      ) : (
        <div
          className={styles.content}
          onClick={focusInput}
          onKeyPress={focusInput}
          role="button"
          tabIndex="0"
        >
          {history.map((item, i) => (
            <React.Fragment key={`${item.ps1}-${i}`}>
              <Prompt ps1={item.ps1} command={item.command} />
              <BashOutput output={item.output} />
            </React.Fragment>
          ))}

          <Prompt ps1={`▲ ${bashState.cwd}`} command="">
            <BashInput textRef={textRef} handleSubmit={runCommand} />
          </Prompt>
          <div ref={contentEndRef} />
        </div>
      )}
    </div>
  );
}

export default Terminal;
