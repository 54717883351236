import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

function Prompt({ps1, command, children}) {
  return (
    <div>
      <span className={styles.prompt}>{ps1}</span>
      <span className={styles.command}>{command}</span>
      {children}
    </div>
  );
}

Prompt.propTypes = {
  ps1: PropTypes.string.isRequired,
  command: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Prompt.defaultProps = {
  children: null,
};

export default Prompt;
