import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';

function Title({title, isSubTitle}) {
  return (
    <h2 className={classNames([styles.root, isSubTitle ? styles.subTitle : styles.mainTitle])}>
      {title}
    </h2>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  isSubTitle: PropTypes.bool.isRequired,
};

Title.defaultProps = {
  isSubTitle: false,
};

export default Title;
