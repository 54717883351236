import React from 'react';

import styles from './index.module.scss';

function PageNotFound() {
  return (
    <div className={styles.root}>
      <p>Sorry, this page was not found</p>
    </div>
  );
}

export default PageNotFound;
