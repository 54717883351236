import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';

function MaterialDesignIcon({icon, className}) {
  return <i className={classNames(styles.icon, className, 'material-icons')}>{icon}</i>;
}

MaterialDesignIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MaterialDesignIcon;
