function isPromise(val) {
  return val && typeof val.then === 'function';
}

export default function promiseMiddleware({dispatch}) {
  return next => action => {
    if (action.pending) {
      return next(action);
    }

    if (isPromise(action.payload)) {
      dispatch({type: action.type, payload: null, error: null, pending: true, meta: action.meta});

      return action.payload.then(
        result => dispatch({...action, payload: result, pending: false}),
        error => {
          return dispatch({...action, payload: null, error, pending: false});
        }
      );
    }
    return next(action);
  };
}
