import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {App, Home, PageNotFound} from 'scenes';

export default (
  <App>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </App>
);
