import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_WIDTH = 217;
const DEFAULT_HEIGHT = 320;

function IPadSVG({children, size}) {
  return (
    <svg
      width={size}
      height="auto"
      viewBox={`-1 -1 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Ipad" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IPAD" transform="translate(0.000000, 1.000000)" stroke="#7E89A3">
          <path
            d="M202.986,317 L12.097,317 C5.462,317 0.083,311.623 0.083,304.99 L0.083,12.093 C0.083,5.46 5.461,0.083 12.097,0.083 L202.986,0.083 C209.622,0.083 215,5.46 215,12.093 L215,304.99 C215,311.623 209.622,317 202.986,317 Z"
            id="bezel"
            strokeWidth="2"
            fill="#FDFDFD"
          />
          <path
            d="M202.986,317 L12.097,317 C5.462,317 0.083,311.623 0.083,304.99 L0.083,12.093 C0.083,5.46 5.461,0.083 12.097,0.083 L202.986,0.083 C209.622,0.083 215,5.46 215,12.093 L215,304.99 C215,311.623 209.622,317 202.986,317 Z"
            id="bezel-2"
            strokeWidth="2"
            fill="#FDFDFD"
          />
          <rect id="screen" fill="#FFFFFF" x="17" y="32" width="181.999" height="252.917" />
          <foreignObject x="17" y="32" width="181.999" height="252.917">
            {children}
          </foreignObject>
          <circle id="lock" cx="108.021" cy="300.021" r="8.021" />
          <circle id="camera" cx="106.99" cy="16.99" r="2.99" />
        </g>
      </g>
    </svg>
  );
}

IPadSVG.propTypes = {
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

IPadSVG.defaultProps = {
  size: `${DEFAULT_WIDTH}px`,
};

export default IPadSVG;
