import {CREATE_EMPLOYEE, GET_EMPLOYEES} from 'store/action-types';

export default (state = {}, action) => {
  const {payload} = action;

  if (action.error) {
    return state;
  } else if (action.pending) {
    return state;
  }
  switch (action.type) {
    case CREATE_EMPLOYEE: {
      return {employees: [...state['employees'], payload.employee]};
    }
    case GET_EMPLOYEES: {
      return {employees: payload.employees};
    }
    default:
      return state;
  }
};
