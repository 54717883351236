import React from 'react';
// import {useSelector} from 'react-redux';

import {Title} from 'components/Widgets';

import {Device, Terminal} from './components';

import styles from './index.module.scss';

function Home() {
  // const projects = useSelector(({data: {projects}}) => projects);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Title title="Hey!" />
          <Title title="I'm Darri Konn" isSubTitle={true} />
        </div>
        <Device>
          <Terminal />
        </Device>
      </div>
    </div>
  );
}

export default Home;
