import {combineReducers} from 'redux';

import bash from './bash';
import data from './data';
import employee from './employee';

export default combineReducers({
  bash,
  data,
  employee,
});
