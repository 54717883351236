const REGEXP = /([^(\s)'"]([^(\s)'"]*(['"])([^\3]*?)\3)+[^(\s)'"]*)|[^(\s)'";(&&)]+|(['"])([^\5]*?)\5|(;|&&)/gi;

const firstString = (...args) => {
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (typeof arg === 'string' || arg instanceof String) {
      return arg;
    }
  }
};

export default value => {
  const myArray = [];
  let match;
  do {
    match = REGEXP.exec(value);
    if (match !== null) {
      // Index 1 in the array is the captured group if it exists
      // Index 0 is the matched text, which we use if no captured group exists
      myArray.push(firstString(match[1], match[6], match[0]));
    }
  } while (match !== null);

  return myArray;
};
