import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import * as serviceWorker from 'services/serviceWorker';
import store from 'store';

import routes from './routes';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter children={routes} />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
