import {GET_PROJECTS} from 'store/action-types';
import data from 'store/data.json';

export default (state = data, action) => {
  // const {payload} = action;

  if (action.error) {
    return state;
  } else if (action.pending) {
    return state;
  }
  switch (action.type) {
    case GET_PROJECTS:
      return state['projects'];
    default:
      return state;
  }
};
