import React from 'react';
import PropTypes from 'prop-types';

import {MacbookSVG, IPadSVG, IPhoneSVG} from 'components/Icons';
import {useWindowDimensions} from 'hooks';
import theme from 'consts/_theme.scss';

import styles from './index.module.scss';

function Device({children}) {
  const {width} = useWindowDimensions();
  const content = (
    <div xmlns="http://www.w3.org/1999/xhtml" className={styles.content}>
      {children}
    </div>
  );

  if (width <= parseFloat(theme.screenSm)) {
    return <IPhoneSVG size="100%" children={content} />;
  } else if (width <= parseFloat(theme.screenMd)) {
    return <IPadSVG size="100%" children={content} />;
  } else {
    return <MacbookSVG size="100%" children={content} />;
  }
}

Device.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Device;
