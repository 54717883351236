import React from 'react';
import PropTypes from 'prop-types';

import {Social} from './components';

import styles from './index.module.scss';

function App({children}) {
  return (
    <div className={styles.root}>
      <main className={styles.content}>{children}</main>
      <div className={styles.cvDownload}>
        <a
          href="https://s3-eu-west-1.amazonaws.com/darrikonn.com/cv.pdf"
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Download CV
        </a>
      </div>
      <Social />
    </div>
  );
}

App.propTypes = {
  children: PropTypes.node,
};

export default App;
