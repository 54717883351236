import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {STDOUT} from 'services/bash';

import styles from './index.module.scss';

function BashOutput({output}) {
  return output.map((command, i) => (
    <div key={`${command}-${i}`} className={styles.root}>
      {Array.isArray(command) ? (
        command.map((o, j) => (
          <div key={`${o}-${i}-${j}`} className={classNames([styles.output, styles[o.type]])}>
            {o.output}
          </div>
        ))
      ) : (
        <div className={styles.output}>{command.output}</div>
      )}
    </div>
  ));
}

BashOutput.propTypes = {
  output: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(STDOUT),
      PropTypes.arrayOf(PropTypes.instanceOf(STDOUT)),
    ])
  ).isRequired,
};

export default BashOutput;
