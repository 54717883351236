import stringArgv from 'lib/stringArgv';
import {BASH} from 'store/action-types';

class STDOUT {
  constructor(output, type = 'string') {
    this.output = output;
    this.type = type;
  }
}

const parse = command => {
  return stringArgv(command).reduce(
    (acc, next) => {
      if (next === BASH.SEMICOLON || next === BASH.LOGICAL_AND) {
        return [...acc, []];
      }
      acc[acc.length - 1] = [...acc[acc.length - 1], next];
      return acc;
    },
    [[]]
  );
};

const command = (state, parsed) => {
  switch ((parsed[0] || '').toUpperCase()) {
    case BASH.PWD: {
      return new STDOUT(`/${state['cwd']}`);
    }
    case BASH.ECHO: {
      return new STDOUT(parsed.slice(1).reduce((acc, next) => `${acc} ${next}`, ''));
    }
    case BASH.LS: {
      const traverse = (fileSystem, dir) => {
        if (dir && dir.length) {
          return traverse(fileSystem[dir[0]], dir.slice(1));
        }
        return Object.keys(fileSystem).map(
          k => new STDOUT(k, typeof fileSystem[k] === 'object' ? 'directory' : 'file')
        );
      };
      return traverse(state.fileSystem, state['cwd'].split('/'));
    }
    case '':
      return new STDOUT('');
    default:
      return new STDOUT(`dksh: command not found: ${parsed[0]}`);
  }
};

const bash = (state, input) =>
  parse(input).reduce((acc, parsed) => [...acc, command(state, parsed)], []);

export {STDOUT};
export default bash;
