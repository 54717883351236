/*** EMPLOYEES ***/
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';

/*** DATA ***/
export const GET_DATA = 'GET_DATA';
export const GET_PROJECTS = 'GET_PROJECTS';

/*** BASH ***/
export const BASH = {
  PWD: 'PWD',
  ECHO: 'ECHO',
  LS: 'LS',
  SEMICOLON: ';',
  LOGICAL_AND: '&&',
  /*
  CD,
  BACKGROUND, // &
  JOBS,
  HELP,
  MAN,
  CAT,
  TOUCH,
  RM,
  RMDIR,
  MV,
  CP,
  EXIT,
  CLEAR,
  GLOB, // *
  WHICH,
  PRINTF,
  SUDO,
  SU,
  REPEAT, // !!
  SLEEP,
  CTRL_C,
  PIPE, // |
  OVERWRITE_RIGHT, // >
  APPEND_RIGHT, // >>
  OVERWRITE_LEFT, // <
  APPEND_LEFT, // <<
  DATE,
  WHOAMI,
  USERS,
  USERADD,
  USERDEL,
  GROUPS,
  GROUPADD,
  GROUPDEL,
  GREP,
  VI,
  */
};
export const PWD = 'PWD';
export const LS = 'LS';
export const CD = 'CD';
export const SEMICOLON = 'SEMICOLON'; // ;
export const LOGICAL_AND = 'LOGICAL_AND'; // &&
export const BACKGROUND = 'BACKGROUND'; // &
export const JOBS = 'JOBS';
export const HELP = 'HELP';
export const MAN = 'MAN';
export const CAT = 'CAT';
export const TOUCH = 'TOUCH';
export const RM = 'RM';
export const RMDIR = 'RMDIR';
export const MV = 'MV';
export const CP = 'CP';
export const EXIT = 'EXIT';
export const CLEAR = 'CLEAR';
export const GLOB = 'GLOB'; // *
export const WHICH = 'WHICH';
export const ECHO = 'ECHO';
export const PRINTF = 'PRINTF';
export const SUDO = 'SUDO';
export const SU = 'SU';
export const REPEAT = 'REPEAT'; // !!
export const SLEEP = 'SLEEP';
export const CTRL_C = 'CTRL_C';
export const PIPE = 'PIPE'; // |
export const OVERWRITE_RIGHT = 'OVERWRITE_RIGHT'; // >
export const APPEND_RIGHT = 'APPEND_RIGHT'; // >>
export const OVERWRITE_LEFT = 'OVERWRITE_LEFT'; // <
export const APPEND_LEFT = 'APPEND_LEFT'; // <<
export const DATE = 'DATE';
export const WHOAMI = 'WHOAMI';
export const USERS = 'USERS';
export const USERADD = 'USERADD';
export const USERDEL = 'USERDEL';
export const GROUPS = 'GROUPS';
export const GROUPADD = 'GROUPADD';
export const GROUPDEL = 'GROUPDEL';
export const GREP = 'GREP';
export const VI = 'VI';
